import { useEffect, useState } from "react";

export default function Countdown({
  time,
  up = false,
}: {
  time: number;
  up?: boolean;
}) {
  const [counter, setCounter] = useState<number>(time);

  useEffect(() => {
    setCounter(time);
    const interval = setInterval(() => {
      setCounter((prev) => {
        if (up) return prev + 1;
        return prev - 1;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [time, up]);

  return <p>{counter}</p>;
}
