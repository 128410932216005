import { FaBell } from "react-icons/fa6";



interface EventReminderProps {
    reminders: {
        useDefault: boolean;
        overrides?: Array<{
            method: 'email' | 'popup' | 'sms';
            minutes: number;
        }> | undefined;
    };
}

export function EventReminder({ reminders }: EventReminderProps) {
    if (reminders.useDefault || !reminders.overrides?.length) return null;

    const formatReminderText = (minutes: number, method: string) => {
        if (minutes >= 1440) {
            const days = Math.floor(minutes / 1440);
            return `${days} day${days > 1 ? 's' : ''} before by ${method}`;
        }
        if (minutes >= 60) {
            const hours = Math.floor(minutes / 60);
            return `${hours} hour${hours > 1 ? 's' : ''} before by ${method}`;
        }
        return `${minutes} minute${minutes > 1 ? 's' : ''} before by ${method}`;
    };

    return (
        <div className="space-y-2">
            {reminders.overrides.map((reminder, index) => (
                <div key={index} className="flex items-center gap-2 text-gray-700">
                    <FaBell className="w-5 h-5" />
                    <span>{formatReminderText(reminder.minutes, reminder.method)}</span>
                </div>
            ))}
        </div>
    );
}