import { FileType } from "./file";

export type Message = {
  _id: string;
  localId?: string;
  sender: {
    _id: string;
    userName: string;
  };
  message?: string;
  file?: FileType;
  createdAt: string;
  updatedAt: string;
  state?: MessageState;
};

export enum MessageState {
  SENDING = "sending",
  SENT = "sent",
  FAIL = "fail",
}
