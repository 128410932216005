import ReactDOMServer from "react-dom/server";
import Notif from "../components/notification/index";

export default function toastError(props: { title: string }) {
  const portal = document.getElementById("portal");
  const div = document.createElement("div");
  const htmlContent = ReactDOMServer.renderToString(
    <Notif
      {...props}
      severity="error"
      onClose={() => {
        portal?.removeChild(div);
      }}
    />
  );

  div.innerHTML = htmlContent;
  const svgElement = div.querySelector('svg');
  portal?.appendChild(div);
  const timeout = setTimeout(() => {
    portal?.removeChild(div);
  }, 5000);
  if (svgElement) {
    svgElement.onclick = function (e) {
      e.stopPropagation();
      portal?.removeChild(div)
      clearTimeout(timeout)
    }
  }

}
