import { Button, Tabs } from "flowbite-react";
import EmbeddedGoogleCalender from "../../components/GoogleCalender";
import CalenderList from "../../components/GoogleCalender/CalenderList";
import RequestAccess from "./components/RequestAccess";
import AddEventModal from "./components/AddEventModal";
import FollowupsTable from "./components/FollowupsTable";
import { useState } from "react";
import { useAuthStore } from "../../stores/auth-store";

export function Followups() {
  const [modal, setModal] = useState({
    add: false,
  });
  const calender = useAuthStore((state) => state.user?.calender);
  return calender?.access ? (
    <div className="w-full flex flex-col">
      <div className="flex p-4 justify-between flex-row w-full">
        <CalenderList />
        <Button onClick={() => setModal((prev) => ({ ...prev, add: true }))}>
          + Add
        </Button>
      </div>
      <Tabs>
        <Tabs.Item active title="Calender">
          <EmbeddedGoogleCalender calenderId={calender.id} />
        </Tabs.Item>
        <Tabs.Item title="Table">
          <FollowupsTable />
        </Tabs.Item>
      </Tabs>
      <AddEventModal
        show={modal.add}
        onClose={() => setModal((prev) => ({ ...prev, add: false }))}
      />
    </div>
  ) : (
    <div className="h-screen flex items-center justify-center w-screen">
      <RequestAccess />
    </div>
  );
}
