import { create } from "zustand";

interface FollowupStore {
  followUp: gapi.client.calendar.Event | null;
}

export const useFollowupStore = create<FollowupStore>(() => ({
  followUp: null,
}));

export const setFollowup = (followUp: gapi.client.calendar.Event | null) =>
  useFollowupStore.setState(() => ({
    followUp,
  }));
