import * as yup from "yup";

export default async function validate(
  schema: yup.AnyObjectSchema,
  value: any
): Promise<null | { [s: string]: { message: string; type: string } }> {
  //@ts-ignore
  return schema
    .validate(value, { abortEarly: false })
    .then(() => null)
    .catch((err: yup.ValidationError) => {
      return (err.inner || []).reduce((prev, err) => {
        const path = err.path?.length ? err.path : "defualt";
        if (!(path in prev)) {
          prev[path] = {
            message: err.message,
            type: err.type,
          };
        }
        return prev;
      }, {} as Record<string, any>);
    });
}
