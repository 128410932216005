import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Layout from "./components/layout";
import { Requests } from "./pages/requests";
import { Patients } from "./pages/patients";
import { Followups } from "./pages/followups";
import Chat from "./pages/Chat";
import { Login } from "./pages/login";
import { ForgetPass } from "./pages/forget-pass";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ProtectRoute from "./components/ProtextRoute";
import Followup from "./pages/followups/followup";

const client = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectRoute>
                <Layout>
                  <Dashboard />
                </Layout>
              </ProtectRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPass />} />
          <Route
            path="/requests"
            element={
              <ProtectRoute>
                <Layout>
                  <Requests />
                </Layout>
              </ProtectRoute>
            }
          />
          <Route
            path="/requests/:request/chat"
            element={
              <ProtectRoute>
                <Layout>
                  <Chat />
                </Layout>
              </ProtectRoute>
            }
          />
          <Route
            path="/patients"
            element={
              <ProtectRoute>
                <Layout>
                  <Patients />
                </Layout>
              </ProtectRoute>
            }
          />
          <Route
            path="/followups"
            element={
              <ProtectRoute>
                <Layout>
                  <Followups />
                </Layout>
              </ProtectRoute>
            }
          />
          <Route
            path="/followups/:followup"
            element={
              <ProtectRoute>
                <Layout>
                  <Followup />
                </Layout>
              </ProtectRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
