import FileMessage from "./file-message";
import TextMessage from "./text-message";
import { useChat } from "./context/ChatProvider";
export default function MessageCard(props: { id: string; userId: string }) {
  const { messages } = useChat();
  const message = messages[props.id];
  if (message.message)
    return <TextMessage {...message} userId={props.userId} />;

  if (message.file) return <FileMessage {...message} userId={props.userId} />;

  return null;
}
