import { useQuery } from "@tanstack/react-query";
import ApexCharts from "apexcharts";
import { useEffect, useRef } from "react";
import { DEFAULT_CACHE_CONFIG } from "../../../constants/api";
import { useAuthStore } from "../../../stores/auth-store";
import { FollowupsStatus } from "../../../types/followup";
import apiCaller from "../../../utils/api-caller";
import { Link } from "react-router-dom";

export default function Chart() {
  const ref = useRef<HTMLDivElement>(null);
  let chartRef = useRef<ApexCharts>();
  const calender = useAuthStore((state) => state.user?.calender);
  const followupsStatus = useQuery({
    queryKey: ["followups", "status"],
    queryFn: () =>
      apiCaller<FollowupsStatus>({
        method: "GET",
        url: "/followups/status",
      }),
    enabled: !!calender?.id,
    ...DEFAULT_CACHE_CONFIG,
  });
  useEffect(() => {
    const near = followupsStatus.data?.nearFollowups?.length || 0.5;
    const today = followupsStatus?.data?.todayFollowups?.length || 0.5;
    const total = near + today;
    const nearPercentage = (near / total) * 100;
    const todayPercentage = (today / total) * 100;
    const series = [nearPercentage, todayPercentage];
    const getChartOptions = () => {
      return {
        series: series,
        colors: ["#C62E2E", "#FFAD60"],
        chart: {
          height: "100%",
          width: "100%",
          type: "pie",
        },
        stroke: {
          colors: ["white"],
          lineCap: "",
        },
        plotOptions: {
          pie: {
            labels: {
              show: true,
            },
            size: "100%",
            dataLabels: {
              offset: -25,
            },
          },
        },
        labels: ["Today", "Near"],
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "Inter, sans-serif",
          },
        },
        legend: {
          position: "bottom",
          fontFamily: "Inter, sans-serif",
        },
        yaxis: {
          labels: {
            formatter: function (value: number) {
              return value + "%";
            },
          },
        },
        xaxis: {
          labels: {
            formatter: function (value: number) {
              return value + "%";
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
      };
    };
    if (ref.current && !chartRef.current) {
      chartRef.current = new ApexCharts(ref.current, getChartOptions());
      chartRef.current.render();
    } else if (chartRef.current) {
      chartRef.current.updateOptions(getChartOptions());
    }
  }, [ref, chartRef, followupsStatus?.data]);

  return !calender?.id ? (
    <div className="max-sm:hidden flex-col gap-4 min-h-[300px] !font-pinar shadow-md w-full max-w-full p-4 h-max min-w-max rounded-md bg-neutral-50">
      <div className="w-full flex items-center justify-between">
        <p className="title capitalize font-medium">recent FollowUps Chart</p>
      </div>
      <div className="w-full h-full flex items-center justify-center min-h-[30vh]">
        <Link
          to={"/followups"}
          className="rounded-md bg-blue-500 px-4 py-2 text-white text-sm max-sm:text-xs"
        >
          Give Access to Use Followups
        </Link>
      </div>
    </div>
  ) : (
    <div
      ref={ref}
      className="max-sm:hidden border border-neutral-200 h-full !font-pinar shadow-lg w-1/2 p-1 min-w-[250px] rounded-md bg-neutral-50"
    >
      <div className="p-4">
        <h5 className="leading-none text-xl font-bold text-gray-900 dark:text-white pb-2">
          follow ups
        </h5>
      </div>
    </div>
  );
}
