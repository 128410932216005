import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import { Avatar, Button, Dropdown, Modal, Navbar } from "flowbite-react";
import { HiChartPie, HiInbox, HiTable, HiUser } from "react-icons/hi";
import { IoLogOut } from "react-icons/io5";
import { useAuthStore } from "../stores/auth-store";

export default function Appbar({
  userName,
  active,
  handleActiveTab,
  toggleModal,
}: {
  userName: string;
  active: string;
  handleActiveTab: (e: string) => void;
  toggleModal: (e: boolean) => void;
}) {
  const initials = userName
    .split(" ")
    .map((item) => item[0])
    .join();

  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  return (
    <Navbar fluid rounded className="bg-[#f9fafb]">
      <Navbar.Brand href="https://pharmacanada.ca/">
        <img src={Logo} className="mr-1 h-7 sm:h-9" alt="Flowbite React Logo" />
        <span className="self-center max-xs:hidden whitespace-nowrap max-sm:text-lg sm:text-xl font-semibold dark:text-white">
          Pharma Canada
        </span>
      </Navbar.Brand>
      <div className="flex md:order-2">
        <Dropdown
          arrowIcon={false}
          inline
          label={
            <div className="flex text-sm max-sm:hidden items-center gap-1 capitalize">
              {userName}
              <Avatar
                alt="User settings"
                placeholderInitials={initials}
                rounded
              />
            </div>
          }
        >
          <Dropdown.Header>
            <span className="block text-sm">{user?.pharmacy}</span>
            <span className="block truncate text-sm font-medium">
              {user?.email}
            </span>
          </Dropdown.Header>
          <Dropdown.Item onClick={() => navigate("/")}>Dashboard</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => toggleModal(true)}>
            Logout
          </Dropdown.Item>
        </Dropdown>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <div className="md:hidden">
          <Navbar.Link
            active={active === "dashboard"}
            onClick={() => {
              handleActiveTab("dashboard");
              navigate("/");
            }}
            // href="/"

            // icon={HiChartPie}
          >
            <div className="flex items-center gap-2">
              <HiChartPie />
              Dashboard
            </div>
          </Navbar.Link>
          <Navbar.Link
            active={active === "requests"}
            onClick={() => {
              handleActiveTab("requests");
              navigate("/requests");
            }}
            // href="/requests"
            icon={HiInbox}
            labelColor={"blue"}
            label="3"
          >
            <div className="flex items-center gap-2">
              <HiInbox />
              Requests
            </div>
          </Navbar.Link>
          <Navbar.Link
            active={active === "patients"}
            onClick={() => {
              handleActiveTab("patients");
              navigate("/patients");
            }}
            // href="/patients"
            icon={HiUser}
          >
            <div className="flex items-center gap-2">
              <HiUser />
              Patients
            </div>
          </Navbar.Link>
          <Navbar.Link
            active={active === "followups"}
            onClick={() => {
              handleActiveTab("followups");
              navigate("/followups");
            }}
            // href="/followups"
            icon={HiTable}
          >
            <div className="flex items-center gap-2">
              <HiTable />
              FollowUps
            </div>
          </Navbar.Link>
          <Navbar.Link onClick={() => toggleModal(true)} icon={IoLogOut}>
            <div className="flex items-center gap-2 sm:hidden">
              <IoLogOut />
              Logout
            </div>
          </Navbar.Link>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}
