import { MessageState as State } from "../../types/message";
import { TbChecks } from "react-icons/tb";
import { IoIosInformationCircle } from "react-icons/io";
import { RiLoader2Fill } from "react-icons/ri";

export default function MessageState({ state }: { state: State }) {
  switch (state) {
    case State.FAIL:
      return (
        <div className="flex items-center text-red-500 gap-1 text-xs">
          <IoIosInformationCircle />
          failed
        </div>
      );
    case State.SENDING:
      return (
        <div className="flex text-yellow-400 items-center gap-1 text-xs">
          <RiLoader2Fill />
          sending
        </div>
      );
    case State.SENT:
      return (
        <div className="flex text-green-500 items-center gap-1 text-xs">
          <TbChecks />
          sent
        </div>
      );
  }
}
