import { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../stores/auth-store";
import { useShallow } from "zustand/shallow";

export default function ProtectRoute({ children }: PropsWithChildren) {
  const { user, loading } = useAuthStore(
    useShallow((state) => ({
      loading: state.loading,
      user: state.user,
    }))
  );
  const location = useLocation();
  if (loading) return <p>Is Loading</p>;

  if (!user) return <Navigate to="login" state={{ from: location }} replace />;
  return <>{children}</>;
}
