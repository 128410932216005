import { Button, FloatingLabel, Modal } from "flowbite-react";
import { useState } from "react";
import * as yup from "yup";
import { YupValidationError } from "../../types/yup.error";
import formData2Json from "../../utils/formData2Json";
import validate from "../../utils/yupValidate";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiCaller, { ApiError } from "../../utils/api-caller";
import { useAuthStore } from "../../stores/auth-store";
import toastError from "../../utils/toastError";
import { StatusCodes } from "http-status-codes";
import { Patient } from "../../types/patient";
import Countdown from "./components/Countdown";
import LocationInput from "../../components/inputs/location-input";
const schema = yup.object().shape({
  email: yup.string().email().required(),
  userName: yup.string().required(),
  phone: yup.string().required(),
  province: yup.string().required(),
  city: yup.string().required(),
});

export default function AddPatientModal({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const [error, setError] = useState<
    YupValidationError<yup.InferType<typeof schema>>
  >({});
  const userId = useAuthStore((state) => state.user?._id);
  const client = useQueryClient();
  const addPatient = useMutation({
    mutationFn: (data: Record<string, any>) =>
      apiCaller<void>({
        method: "POST",
        url: `/owner/${userId}/patients`,
        body: JSON.stringify(data),
        errorHandler(error) {
          switch (error.status) {
            case StatusCodes.CONFLICT:
              if (error.result?.message)
                return toastError({ title: "already in your patient list" });
          }
        },
      }),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ["patients"],
      });
      handleClose();
    },
  });
  const confirmAdd = useMutation({
    mutationFn: (patientId: string) =>
      apiCaller<void>({
        method: "POST",
        url: `/owner/${userId}/patients/confirm/${patientId}`,
        params: { action: "confirm-add" },
      }),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ["patients"],
      });
      handleClose();
    },
  });
  function handleClose() {
    addPatient.reset();
    confirmAdd.reset();
    onClose();
  }
  const shouldConfirm =
    addPatient.isError &&
    (addPatient.error as unknown as ApiError)?.result?._id;
  const patient = (addPatient.error as unknown as ApiError)?.result as Patient;
  return (
    <Modal show={show} onClose={handleClose}>
      {shouldConfirm ? (
        <>
          <Modal.Header>Confirm Add</Modal.Header>
          <Modal.Body>
            <p>{patient.userName}</p>
            <p>{patient.email}</p>
            <p>
              {patient.province}-{patient.city}
            </p>
            <Countdown time={120} />
          </Modal.Body>
          <Modal.Footer className=" border-0 justify-center gap-4">
            <Button
              className="sm:min-w-40"
              type="button"
              disabled={confirmAdd.isPending}
              onClick={() => {
                confirmAdd.mutate(patient._id);
              }}
            >
              Confirm
            </Button>
            <Button
              className="sm:min-w-40"
              onClick={handleClose}
              disabled={confirmAdd.isPending}
            >
              Cancle
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            const formData = new FormData(event.target as any);
            const data = formData2Json(formData);
            const error = await validate(schema, data);
            if (error) {
              return setError(error);
            }
            setError({});
            addPatient.mutate(data);
          }}
        >
          <Modal.Header>Add Patient</Modal.Header>
          <Modal.Body className="min-h-[39vh]">
            <FloatingLabel
              variant="outlined"
              label="userName"
              name="userName"
              color={error.userName ? "error" : "default"}
              helperText={error.userName?.message}
            />

            <LocationInput/>

            <div className="relative">
              <span className="absolute end-4 bottom-4 text-gray-500 dark:text-gray-400">
                <svg className="w-4 h-4 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19 18">
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                </svg>
              </span>
              <FloatingLabel
                variant="outlined"
                name="email"
                label="email"
                type="email"
                color={error.email ? "error" : "default"}
                helperText={error.email?.message}
                id="floating-phone-number"
                placeholder=" "
              />
              <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:start-6 peer-focus:start-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Phone number</label>
            </div>

            <div className="relative">
              <span className="absolute end-4 bottom-4 text-gray-500 dark:text-gray-400">
                <svg className="w-4 h-4 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19 18">
                  <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                </svg>
              </span>
              <FloatingLabel
                variant="outlined"
                name="phone"
                label="phone"
                maxLength={11}
                type="tel"
                color={error.phone ? "error" : "default"}
                helperText={error.phone?.message}
                id="floating-phone-number"
                placeholder=" "
              />
              <label className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:start-6 peer-focus:start-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Phone number</label>
            </div>
            
          </Modal.Body>
          <Modal.Footer className=" border-0 justify-center gap-4">
            <Button
              className="sm:min-w-40"
              type="submit"
              disabled={addPatient.isPending}
            >
              Add
            </Button>
            <Button
              className="sm:min-w-40"
              onClick={handleClose}
              disabled={addPatient.isPending}
            >
              Cancle
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
}
