import Chart from "./components/charts";
import RecentRequests from "./requests";
import RecentFollowUps from "./followups";
import RecentTickets from "./tickets";
import { useQuery } from "@tanstack/react-query";
import apiCaller from "../../utils/api-caller";
import { useAuthStore } from "../../stores/auth-store";
import { Request } from "../../types/request";
import { useAppStore } from "../../stores/app-store";

export default function Dashboard() {
  const userId = useAuthStore((state) => state.user?._id);
  const setRequestsStatus = useAppStore((state) => state.setRequestsStatus);
  const requestsStatus = useQuery({
    queryKey: ["requests", "status"],
    queryFn: () =>
      apiCaller<{
        totalNewRequest: number;
        newRequests: Request[];
        totalTickets: number;
        tickets: Request[];
      }>({
        url: `/owners/${userId}/requests/status`,
        method: "GET",
      }).then((res) => {
        setRequestsStatus({
          total: res.totalNewRequest,
          newTickets: res.totalTickets,
          new: 0,
        });
        return res;
      }),
    refetchOnMount: true,
    refetchInterval: 1000 * 30,
  });
  return (
    <div className="w-full h-full overflow-y-auto max-h-[90vh] max-sm:p-2 sm:p-4 gap-4 flex flex-col">
      <div className="w-auto flex gap-4 max-sm:flex-col">
        <RecentTickets
          total={requestsStatus.data?.totalTickets || 0}
          tickets={requestsStatus.data?.tickets || []}
        />
        <Chart />
      </div>
      <div className="w-auto flex gap-4 max-md:flex-col">
        <RecentRequests
          total={requestsStatus.data?.totalNewRequest || 0}
          tickets={requestsStatus.data?.newRequests || []}
        />
        <RecentFollowUps />
      </div>
    </div>
  );
}
