import { Sidebar} from "flowbite-react";
import { HiChartPie, HiInbox, HiTable, HiUser } from "react-icons/hi";
import { IoLogOut } from "react-icons/io5";
import { RequestsStatus } from "../../types/request";
import getRequestsStatusLable from "./utils/get-requests-status-lable";
import { Link } from "react-router-dom";
import SideBarItem from "./components/SidebarItem";

export default function SideBar({
  active,
  handleActiveTab,
  toggleModal,
  requestsStatus,
}: {
  active: string;
  requestsStatus: RequestsStatus;
  handleActiveTab: (e: string) => void;
  toggleModal: (e: boolean) => void;
}) {
  return (
    <Sidebar
      collapseBehavior="collapse"
      aria-label="Sidebar with multi-level dropdown example"
      className="h-[91vh] max-md:hidden min-h-[91vh]"
    >
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          <SideBarItem
            active={active === "dashboard"}
            onClick={() => handleActiveTab("dashboard")}
            href="/"
            icon={HiChartPie}
          >
            Dashboard
          </SideBarItem>

          <SideBarItem
            active={active === "requests"}
            onClick={() => handleActiveTab("requests")}
            href="/requests"
            icon={HiInbox}
            labelColor={"blue"}
            label={getRequestsStatusLable(requestsStatus)}
          >
            Requests
          </SideBarItem>
          <SideBarItem
            active={active === "patients"}
            onClick={() => handleActiveTab("patients")}
            href="/patients"
            icon={HiUser}
          >
            Patients
          </SideBarItem>
          <SideBarItem
            active={active === "followups"}
            onClick={() => handleActiveTab("followups")}
            href="/followups"
            icon={HiTable}
          >
            FollowUps
          </SideBarItem>
          <SideBarItem
            active={active === "logout"}
            onClick={() => {
              toggleModal(true);
              handleActiveTab("logout");
            }}
            href="#"
            icon={IoLogOut}
          >
            Logout
          </SideBarItem>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
}
