export default function Reminder({ reminders, setReminders, handleRemoveReminder, handleAddReminder }: {
    handleAddReminder: () => void,
    handleRemoveReminder: (e:number) => void,
    reminders: { minutes: number; method: "email" | "popup" }[],
    setReminders: (reminders:{ minutes: number, method: "email" | "popup" }[])=> void
}) {
    return (
        <div className="flex gap-4">
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Reminders
                </label>
                <div className="mt-2 space-y-3">
                    {reminders.map((reminder, index) => (
                        <div key={index} className="flex items-center space-x-3">
                            <select
                                value={reminder.minutes}
                                onChange={(e) => {
                                    const newReminders = [...reminders];
                                    newReminders[index].minutes = Number(e.target.value);
                                    setReminders(newReminders);
                                }}
                                className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            >
                                <option value={0}>At time of event</option>
                                <option value={5}>5 minutes before</option>
                                <option value={10}>10 minutes before</option>
                                <option value={30}>30 minutes before</option>
                                <option value={60}>1 hour before</option>
                                <option value={1440}>1 day before</option>
                            </select>
                            <select
                                value={reminder.method}
                                onChange={(e) => {
                                    const newReminders = [...reminders];
                                    newReminders[index].method = e.target.value as
                                        | "email"
                                        | "popup";
                                    setReminders(newReminders);
                                }}
                                className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            >
                                <option value="popup">Notification</option>
                                <option value="email">Email</option>
                            </select>
                            <button
                                type="button"
                                onClick={() => handleRemoveReminder(index)}
                                className="text-red-600 hover:text-red-700"
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleAddReminder}
                        className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Add Reminder
                    </button>
                </div>
            </div>
        </div>

    )
}