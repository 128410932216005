import { Badge, SidebarItemProps } from "flowbite-react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function SideBarItem({
  icon: Icon,
  href,
  active,
  children,
  onClick,
  label,
  labelColor = "info",
}: SidebarItemProps) {
  return (
    <NavLink
      to={href as string}
      //@ts-ignore
      onClick={onClick}
      className={twMerge(
        "flex items-center justify-center rounded-lg p-2 text-base font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700",
        active && "bg-gray-100 dark:bg-gray-700"
      )}
    >
      {Icon && (
        <Icon
          className={twMerge(
            "h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white",
            active && "text-gray-700 dark:text-gray-100"
          )}
        />
      )}
      <span className="flex-1 whitespace-nowrap px-3">{children}</span>
      {label && <Badge color={labelColor}>{label}</Badge>}
    </NavLink>
  );
}
