export default function formData2Json(formData: FormData, keys?: string[]) {
  const obj: Record<string, any> = {};
  formData.forEach((value, key) => {
    // If the key already exists, convert it to an array
    if (!keys || keys.includes(key)) {
      if (obj[key]) {
        if (!Array.isArray(obj[key])) {
          obj[key] = [obj[key]];
        }
        obj[key].push(value);
      } else {
        obj[key] = value;
      }
    }
  });
  return obj;
}
