import moment from "moment";
import ChatInput from "./chatinput";
import { useParams } from "react-router-dom";
import { ChatProvider, useChat } from "./context/ChatProvider";
import MessageCard from "./message";
import { useAuthStore } from "../../stores/auth-store";
import { useScrollToBottom } from "./hooks/useScrollToBottom";

function Chat() {
  const {
    messageIds,
    newSendMessage,
    getNextPage,
    newMessages,
  } = useChat();
  const userId = useAuthStore((state) => state.user?._id);
  const { containerRef } = useScrollToBottom<HTMLDivElement>([newSendMessage]);
  return (
    <div className="w-full h-full flex flex-col gap-5 pt-2 px-2 md:px-8">
      <p className="w-full justify-center text-center text-xs text-neutral-500">
        {moment().format("YYYY MMM DD")}
      </p>
      <div
        ref={containerRef}
        onScroll={(e) => {
          if (e.currentTarget.scrollTop === 0) {
            getNextPage();
          }
          if (
            e.currentTarget.scrollTop + e.currentTarget.offsetHeight >=
            e.currentTarget.scrollHeight
          ) {
            console.log("should reset new messages ??");
          }
        }}
        className="w-auto flex flex-col gap-4 max-h-[70vh] min-h-[70vh] h-auto overflow-y-auto"
      >
        {messageIds.map((id) => (
          <MessageCard userId={userId as string} id={id} key={id} />
        ))}
        {newMessages ? <div>{newMessages}</div> : null}
      </div>
      <ChatInput />
    </div>
  );
}

export default function ChatPage() {
  const { request } = useParams() as { request: string };

  return (
    <ChatProvider title="Chat " id={request}>
      <Chat />
    </ChatProvider>
  );
}
