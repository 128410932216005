import React from "react";
import { FiPaperclip } from "react-icons/fi";
import { FaFile } from "react-icons/fa6";
import { getFileLink } from "../../../utils/file";

interface Attachment {
  name: string;
  url: string;
  size: string;
}

interface EventAttachmentsProps {
  attachment?: Attachment;
}

export function EventAttachments({ attachment }: EventAttachmentsProps) {
  if (!attachment) return null;

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <FiPaperclip className="w-5 h-5" />
        <h3 className="font-medium">Attachments</h3>
      </div>
      <div className="space-y-2">
        <a
          href={getFileLink(attachment.url)}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100 transition-colors"
        >
          <FaFile className="w-4 h-4 text-gray-500" />
          <span className="text-blue-600 hover:underline">
            {attachment.name}
          </span>
        </a>
      </div>
    </div>
  );
}
