import React from 'react';
import { IoRepeat } from "react-icons/io5";
import { RRule } from 'rrule';

interface EventRecurrencyProps {
  recurrence: string[];
}

export function EventRecurrency({ recurrence }: EventRecurrencyProps) {
  if (!recurrence || recurrence.length === 0) return null;

  const getRruleText = (rruleString: string) => {
    // Remove 'RRULE:' prefix if present
    const cleanRrule = rruleString.replace('RRULE:', '');
    const rrule = RRule.fromString(cleanRrule);
    return rrule.toText();
  };

  return (
    <div className="flex items-center gap-2 text-gray-700">
      <IoRepeat className="w-5 h-5" />
      <span>{getRruleText(recurrence[0])}</span>
    </div>
  );
}