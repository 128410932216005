export enum RequestState {
  IDLE = "idle",
  ACCEPT = "accept",
  IN_PROGRESS = "in-progress",
  SENT = "sent",
  REJECT = "reject",
}

export function getRequestStateName(state: RequestState) {
  switch (state) {
    case RequestState.IDLE:
      return "idle";
    case RequestState.ACCEPT:
      return "accept";
    case RequestState.IN_PROGRESS:
      return "in progress";
    case RequestState.SENT:
      return "sent";
    case RequestState.REJECT:
      return "reject";
  }
}
