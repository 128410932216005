export type NotifSeverity = "info" | "error" | "success";
export default function Notif({
  title,
  severity = "info",
  desc,
  image,
  onClose,
}: {
  severity?: NotifSeverity;
  image?: any;
  title: string;
  desc?: string;
  onClose: () => void;
}) {
  const severityColorMapper = {
    info: {
      primary: "sky-600",
      secondary: "sky-50",
      thertiary: "sky-800",
    },
    error: {
      primary: "rose-600",
      secondary: "rose-50",
      thertiary: "rose-800",
    },
    success: {
      primary: "primary-600",
      secondary: "primary-50",
      thertiary: "primary-800",
    },
  } as Record<
    NotifSeverity,
    {
      primary: string;
      secondary: string;
      thertiary: string;
    }
  >;

  return (
    <div
      className={`flex w-full shadow-lg rounded-xl xs:max-w-[400px] xs:min-w-[400px] flex-col border border-${severityColorMapper[severity].primary}  bg-${severityColorMapper[severity].secondary}`}
    >
      <div
        className={`w-full flex justify-between border-b border-${severityColorMapper[severity].primary} p-4`}
      >
        <p
          className={`text-sm max-xs:text-s text-${severityColorMapper[severity].primary} font-bold w-full`}
        >
          {title}
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          fill="currentColor"
          className={`bi cursor-pointer transition-all duration-100 ease-linear hover:scale-110 font-bold bi-x text-${severityColorMapper[severity].primary}`}
          viewBox="0 0 16 16"
        >
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      </div>
      <div
        className={`flex gap-2 py-6 px-4 items-center ${
          !image && !desc && "hidden"
        }`}
      >
        {image && (
          <img
            src={image}
            className="w-[40px] h-auto"
            width={100}
            height={100}
            alt="notif-photo"
          />
        )}
        <p
          className={`text-${severityColorMapper[severity].thertiary} font-medium max-xs:text-xs xs:text-sm`}
        >
          {desc}
        </p>
      </div>
    </div>
  );
}
