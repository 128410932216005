import { Button, FloatingLabel, Pagination, Table } from "flowbite-react";
import { useState } from "react";
import { HiSearch } from "react-icons/hi";
import AddPatientModal from "./modals";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../stores/auth-store";
import apiCaller from "../../utils/api-caller";
import { Patient } from "../../types/patient";
import { getTotalPages } from "../../utils/pagination";
import { PAGINATION_SIZE } from "../../constants/pagination";
import { DEFAULT_CACHE_CONFIG } from "../../constants/api";
export function Patients() {
  const [show, setShow] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const userId = useAuthStore((state) => state.user?._id);
  const { data, isLoading } = useQuery({
    queryKey: ["patients", page],
    queryFn: () =>
      apiCaller<{
        length: number;
        patients: Patient[];
      }>({
        method: "GET",
        url: `/owner/${userId}/patients`,
        params: { page, size: PAGINATION_SIZE },
      }),

    placeholderData: keepPreviousData,
    ...DEFAULT_CACHE_CONFIG,
  });
  return (
    <div className="w-full h-full flex flex-col gap-5 max-sm:p-2 sm:p-4">
      <div className="flex w-auto justify-between items-center">
        <div className="w-fit relative">
          <div className="absolute top-1/3 end-4 flex items-center">
            <HiSearch color="blue" />
          </div>
          <FloatingLabel
            variant="outlined"
            label="Search Patients"
            className="w-fit pr-10"
          />
        </div>
        <Button
          className="bg-neutral-200 hover:text-white text-black"
          onClick={() => setShow(true)}
        >
          + Add Patient
        </Button>
      </div>
      <div className="relative overflow-x-auto">
        <Table hoverable className="w-full">
          <Table.Head>
            <Table.HeadCell>username</Table.HeadCell>
            <Table.HeadCell>email</Table.HeadCell>
            <Table.HeadCell>location</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {isLoading ? (
              <p>Loading</p>
            ) : (
              data?.patients.map((patient, indx) => {
                return (
                  <Table.Row key={patient._id} className="bg-white border-x border-neutral-200">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900">
                      {patient.userName}
                    </Table.Cell>
                    <Table.Cell>{patient.email}</Table.Cell>
                    <Table.Cell>
                      {patient.province}-{patient.city}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
        <Pagination
          currentPage={page}
          previousLabel="prev"
          style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:".5rem"}}
          totalPages={getTotalPages(PAGINATION_SIZE, data?.length || 0)}
          onPageChange={(page) => setPage(page)}
        />
      </div>

      <AddPatientModal show={show} onClose={() => setShow(false)} />
    </div>
  );
}
