import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useShallow } from "zustand/shallow";
import { Message, MessageState } from "../../../types/message";
import { useChatStore } from "../../../stores/chat-store";
import { useAuthStore } from "../../../stores/auth-store";
import apiCaller from "../../../utils/api-caller";

interface ChatContext {
  messages: Record<string, Message>;
  messageIds: string[];
  loading: boolean;
  sendMessage: (message: string) => void;
  sendFile: (file: File) => void;
  getNextPage: () => void;
  haveNextPage: boolean;
  title: string;
  newSendMessage?: string;
  resetNewMessages: () => void;
  newMessages: number;
}
export const chatContext = createContext<ChatContext | undefined>(undefined);
export function ChatProvider(
  props: PropsWithChildren<{
    id: string;
    title: string;
  }>
) {
  const [newSendMessage, setNewSndMessage] = useState<string | undefined>(
    undefined
  );
  const [since] = useState(() => new Date());
  const state = useChatStore(
    useShallow((state) => ({
      updateChat: state.updateChat,
      updateMessage: state.updateMessage,
      addMessage: state.addMessage,
      resetNewMessages: state.resetNewMessages,
    }))
  );
  const user = useAuthStore((state) => state.user);
  const chatState = useChatStore(useShallow((state) => state.chats[props.id]));
  const [page, setPage] = useState<number>(1);
  const getMessages = useQuery({
    queryKey: ["messages", props.id, page],
    queryFn: () =>
      apiCaller<{ messages: Message[]; length: number }>({
        method: "GET",
        url: `/requests/${props.id}/messages`,
        params: {
          page,
          size: 10,
          since,
        },
      }).then((res) => {
        const { ids, messages } = res.messages.reduce(
          (prev, message) => {
            prev.ids.push(message._id);
            prev.messages[message._id] = {
              ...message,
              state: MessageState.SENT,
            };
            return prev;
          },
          {
            ids: [] as string[],
            messages: {} as Record<string, Message>,
          }
        );
        state.updateChat(props.id, messages, ids.reverse(), res.length);

        return res;
      }),
    // ...DEFAULT_CACHE_CONFIG,
    staleTime: Infinity,
    enabled: !!props.id,
  });
  //new messages
  useQuery({
    queryKey: ["new-messages"],
    queryFn: () =>
      apiCaller<{ messages: Message[]; length: number }>({
        method: "GET",
        url: `/requests/${props.id}/messages`,
        params: {
          page,
          size: 10,
          since,
          new: "true",
        },
      }).then((res) => {
        console.log("fet new messages");
        const { ids, messages } = res.messages.reduce(
          (prev, message) => {
            prev.ids.push(message._id);
            prev.messages[message._id] = {
              ...message,
              state: MessageState.SENT,
            };
            return prev;
          },
          {
            ids: [] as string[],
            messages: {} as Record<string, Message>,
          }
        );
        state.updateChat(props.id, messages, ids.reverse(), res.length, true);
        return res;
      }),
    staleTime: 1000 * 30,
    refetchInterval: 1000 * 30,
    refetchOnMount: false,
    enabled: !!props.id,
  });
  const sendMessageMutation = useMutation({
    mutationFn: (data: { id: string; message: string }) =>
      apiCaller<Message>({
        method: "POST",
        url: `/requests/${props.id}/messages`,
        body: JSON.stringify({ message: data.message }),
      }),
    onError(err, data) {
      state.updateMessage(props.id, data.id, {
        state: MessageState.FAIL,
      });
    },
    onSuccess(res, data) {
      state.updateMessage(props.id, data.id, {
        ...res,
        localId: data.id,
        state: MessageState.SENT,
      });
    },
  });
  const sendFileMessageMutation = useMutation({
    mutationFn: (data: { id: string; data: FormData }) =>
      apiCaller<Message>({
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: `/requests/${props.id}/messages`,
        body: data.data,
        formData: true,
      }),
    onError(err, data) {
      state.updateMessage(props.id, data.id, {
        state: MessageState.FAIL,
      });
    },
    onSuccess(res, data) {
      state.updateMessage(props.id, data.id, {
        ...res,
        localId: data.id,
        state: MessageState.SENT,
      });
    },
  });
  function getNextPage() {
    // if (haveNextPage) {
    setPage((prev) => prev + 1);
    // }
  }
  function sendMessage(message: string) {
    const localId = `${Date.now()}-${Math.random() * 1000}`;
    state.addMessage(props.id, {
      _id: localId,
      localId: localId,
      sender: {
        _id: user?._id as string,
        userName: user?.pharmacy as string,
      },
      message,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      state: MessageState.SENDING,
    });
    sendMessageMutation.mutate({ id: localId, message });
    setNewSndMessage(localId);
  }
  function sendFile(file: File) {
    const localId = `${Date.now()}-${Math.random() * 1000}`;

    state.addMessage(props.id, {
      _id: localId,
      localId: localId,
      sender: {
        _id: user?._id as string,
        userName: user?.pharmacy as string,
      },
      file: {
        name: file.name,
        size: file.size,
        url: URL.createObjectURL(file),
      },
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      state: MessageState.SENDING,
    });

    const formData = new FormData();
    formData.append("file", file);
    sendFileMessageMutation.mutate({ id: localId, data: formData });
    setNewSndMessage(localId);
  }

  return (
    <chatContext.Provider
      value={{
        messageIds: chatState?.ids || [],
        messages: chatState?.messages || {},
        loading: getMessages.isLoading || getMessages.isFetching,
        title: props.title,
        haveNextPage: false,
        newMessages: chatState?.newMessages || 0,
        getNextPage,
        sendMessage,
        sendFile,
        newSendMessage,
        resetNewMessages: () => state.resetNewMessages(props.id),
      }}
    >
      {props.children}
    </chatContext.Provider>
  );
}

export const useChat = () => {
  const context = useContext(chatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
