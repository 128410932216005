import { create } from "zustand";
import { RequestsStatus } from "../types/request";
import { createJSONStorage, persist } from "zustand/middleware";

interface AppStore {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  requestsStatus: RequestsStatus;
  setRequestsStatus: (status: Partial<RequestsStatus>) => void;
}

export const useAppStore = create<AppStore>(
  //@ts-ignore
  persist(
    (set, get) => ({
      activeTab: "dashboard",
      setActiveTab(tab) {
        set({ activeTab: tab });
      },
      requestsStatus: {
        total: 0,
        new: 0,
        newTickets: 0,
      },
      setRequestsStatus(status) {
        const tmp = get().requestsStatus;
        Object.assign(tmp, status);
        set({ requestsStatus: tmp });
      },
    }),
    {
      name: "app-store",
      storage: createJSONStorage(() => localStorage),
      partialize(state) {
        return { activeTab: state.activeTab };
      },
    }
  )
);
