import { useRef } from "react";
import { IoSend } from "react-icons/io5";
import { RiAttachment2 } from "react-icons/ri";
import { useChat } from "./context/ChatProvider";

export default function ChatInput() {
  const { sendMessage, sendFile } = useChat();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleAttachmentClick = () => {
    if (fileInputRef.current) {
      //@ts-ignore
      fileInputRef.current.click();
    }
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const data = new FormData(e.target as any);
        const form = e.target as HTMLFormElement;
        const message = data.get("message") as string;
        if (message.trim()) {
          sendMessage(message);
          form.reset();
          return;
        }
      }}
    >
      <div className="relative">
        <textarea
          name="message"
          className="w-full resize-none p-4 !pr-24 max-sm:text-xs text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500"
          placeholder="Write something...."
        />
        <button
          type="button"
          onClick={handleAttachmentClick}
          className="text-white absolute end-5 h-12 top-1/2 -translate-x-1/2 -translate-y-1/2 font-medium rounded-lg text-sm px-4 py-2"
        >
          <RiAttachment2 className="text-blue-800 w-8" size={20} />
        </button>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={(e) => {
            const files = e.target.files;
            if (files?.length) {
              sendFile(files[0]);
            }
          }}
        />
        <button
          type="submit"
          className="text-white absolute -end-2 h-12 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
        >
          <IoSend />
        </button>
      </div>
    </form>
  );
}
