import React from 'react';
import { BsFillClockFill } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";

interface EventDateTimeProps {
    startDate: Date;
    endDate: Date;
}

export function EventDateTime({ startDate, endDate }: EventDateTimeProps) {
    const formatDate = (date: Date) => {
        return date.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const formatTime = (date: Date) => {
        return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    return (
        <div className="space-y-2">
            <div className="flex items-center gap-2 text-gray-700">
                <FaCalendarAlt className="w-5 h-5" />
                <span>{formatDate(startDate)}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-700">
                <BsFillClockFill className="w-5 h-5" />
                <span>{formatTime(startDate)} - {formatTime(endDate)}</span>
            </div>
        </div>
    );
}