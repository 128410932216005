import { Dropdown } from "flowbite-react";
import { HiFilter } from "react-icons/hi";
export function Filter({
  label,
  items,
  onSelect,
}: {
  label: string;
  items: { name: string; id: string }[];
  onSelect: (id: string) => void;
}) {
  return (
    <Dropdown
      label={
        <div className="w-fit flex items-center gap-2">
          <HiFilter />
          {label}
        </div>
      }
      dismissOnClick={false}
      arrowIcon={false}
    >
      {items.map((item) => {
        return (
          <Dropdown.Item key={item.id} onClick={() => onSelect(item.id)}>
            {item.name}
          </Dropdown.Item>
        );
      })}
    </Dropdown>
  );
}
