import * as React from "react";
import { Button, getTheme, Label, TextInput } from "flowbite-react";
import logo from "../../assets/images/logo.png";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { MouseEventHandler, useState } from "react";
import ClassNames from "classnames";
import { useMutation } from "@tanstack/react-query";
import apiCaller from "../../utils/api-caller";
import { User } from "../../types/user";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import validate from "../../utils/yupValidate";
import { YupValidationError } from "../../types/yup.error";
import { useAuthStore } from "../../stores/auth-store";
import { StatusCodes } from "http-status-codes";
import toastError from "../../utils/toastError";
const passIcon = (visibility: boolean, callback: MouseEventHandler) => {
  const Icon = visibility ? FaEye : FaEyeSlash;
  const className = "text-congress-blue-900";
  return (
    <Icon
      className={ClassNames(
        getTheme().textInput.field.icon.svg,
        "cursor-pointer",
        visibility && className
      )}
      onClick={callback}
    />
  );
};

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});
export function Login() {
  console.log("heare in login page");
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<
    YupValidationError<yup.InferType<typeof loginSchema>>
  >({});
  const setUser = useAuthStore((state) => state.setUSer);
  const navigate = useNavigate();
  const location = useLocation();
  const login = useMutation({
    mutationFn: (input: { email: string; password: string }) =>
      apiCaller<User>({
        method: "POST",
        url: "/auth/owner/login",
        body: JSON.stringify(input),
        errorHandler: (error) => {
          switch (error.status) {
            case StatusCodes.UNAUTHORIZED:
              return toastError({ title: "invalid username or password" });
            default:
              return toastError({
                title: "somthing happen wrong please try again later",
              });
          }
        },
      }),
    onSuccess: (data) => {
      setUser(data);
      const from = (location.state as any)?.from?.pathname || "/";
      navigate(from, { replace: true });
    },
  });

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-neutral-50">
      <form
        className="flex max-w-md flex-col gap-4 shadow-md scale-in bg-white rounded-lg p-8 sm:min-w-[370px]"
        onSubmit={async (event) => {
          event.preventDefault();
          // event.stopPropagation();
          const formData = new FormData(event.target as any);
          const data = {
            email: formData.get("email") as string,
            password: formData.get("password") as string,
          };
          const error = await validate(loginSchema, data);
          if (error) {
            return setError(error);
          }
          setError({});
          login.mutate(data);
        }}
      >
        <div className="flex flex-col w-auto justify-center gap-2 items-center">
          <img src={logo} alt="logo" className="" width={50} height={50} />
          <p className="title capitalize text-neutral-700 text-2xl">
            admin panel
          </p>
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email" value="Your email" />
          </div>
          <TextInput
            name="email"
            type="email"
            placeholder="email@gmail.com"
            color={error.email ? "failure" : ""}
            helperText={error.email?.message}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password" value="Your password" />
          </div>
          <TextInput
            name="password"
            type={show ? "text" : "password"}
            rightIcon={() => passIcon(show, () => setShow((prev) => !prev))}
            theme={{
              field: {
                icon: {
                  base: getTheme().textInput.field.icon.base.replace(
                    "pointer-events-none",
                    " "
                  ),
                },
              },
            }}
            color={error.password ? "failure" : ""}
            helperText={error.password?.message}
          />
        </div>
        {/* <div className="flex items-center gap-2">
          <Checkbox id="remember" />
          <Label htmlFor="remember">Remember me</Label>
        </div> */}
        <div>
          <p>if you want to signup contact us</p>
        </div>
        <Button type="submit" disabled={login.isPending}>
          Login
        </Button>
      </form>
    </div>
  );
}
