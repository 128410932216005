import { useMutation, useQuery } from "@tanstack/react-query";
import { DEFAULT_CACHE_CONFIG } from "../../constants/api";
import { useShallow } from "zustand/shallow";
import { Dropdown } from "flowbite-react";
import apiCaller from "../../utils/api-caller";
import { useAuthStore } from "../../stores/auth-store";
import { User } from "../../types/user";

export default function CalenderList() {
  const { user, setUser } = useAuthStore(
    useShallow((state) => ({
      user: state.user,
      setUser: state.setUSer,
    }))
  );
  const calenders = useQuery({
    queryKey: ["calender-list"],
    queryFn: () =>
      apiCaller<{ id: string; summary: string; timeZone: string }[]>({
        method: "GET",
        url: `/calenders`,
      }),
    enabled: user?.calender?.access,
    ...DEFAULT_CACHE_CONFIG,
  });
  const setCalenderMutation = useMutation({
    mutationFn: (calender: { id: string; summary: string; timeZone: string }) =>
      apiCaller<void>({
        method: "POST",
        url: "/calenders",
        body: JSON.stringify(calender),
      }),
    onSuccess(_, data) {
      setUser({
        ...(user as User),
        calender: {
          access: true,
          ...data,
        },
      });
    },
  });
  if (calenders.isLoading) return <div>Loading</div>;
  return (
    <Dropdown label={user?.calender?.summary || "Select Calender"}>
      <Dropdown.Header className="">
        <span className="block text-xs  font-bold text-blue-900">
          choose google calendar data
        </span>
      </Dropdown.Header>
      {calenders.data?.map((calendar) => {
        return (
          <Dropdown.Item
            className={`${user?.calender?.id === calendar.id && "bg-blue-200"}`}
            onClick={() => {
              setCalenderMutation.mutate(calendar);
            }}
            key={calendar.id}
          >
            {calendar.summary}
          </Dropdown.Item>
        );
      })}
    </Dropdown>
  );
}
