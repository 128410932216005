export const provinces: { name: string; cities: string[] }[] = [
  {
    name: "Alberta",
    cities: ["Calgary", "Edmonton", "Red Deer", "Lethbridge", "St. Albert"],
  },
  {
    name: "British Columbia",
    cities: ["Vancouver", "Victoria", "Surrey", "Burnaby", "Kelowna"],
  },
  {
    name: "Manitoba",
    cities: ["Winnipeg", "Brandon", "Steinbach", "Thompson", "Selkirk"],
  },
  {
    name: "New Brunswick",
    cities: ["Fredericton", "Moncton", "Saint John", "Dieppe", "Miramichi"],
  },
  {
    name: "Newfoundland and Labrador",
    cities: [
      "St. John's",
      "Corner Brook",
      "Gander",
      "Grand Falls-Windsor",
      "Mount Pearl",
    ],
  },
  {
    name: "Nova Scotia",
    cities: ["Halifax", "Sydney", "Dartmouth", "Truro", "New Glasgow"],
  },
  {
    name: "Ontario",
    cities: ["Toronto", "Ottawa", "Mississauga", "Brampton", "Hamilton"],
  },
  {
    name: "Prince Edward Island",
    cities: ["Charlottetown", "Summerside", "Montague", "Souris", "Kensington"],
  },
  {
    name: "Quebec",
    cities: ["Quebec City", "Montreal", "Laval", "Gatineau", "Trois-Rivières"],
  },
  {
    name: "Saskatchewan",
    cities: [
      "Saskatoon",
      "Regina",
      "Prince Albert",
      "Moose Jaw",
      "Swift Current",
    ],
  },
  {
    name: "Northwest Territories",
    cities: [
      "Yellowknife",
      "Hay River",
      "Inuvik",
      "Fort Smith",
      "Norman Wells",
    ],
  },
  {
    name: "Nunavut",
    cities: [
      "Iqaluit",
      "Rankin Inlet",
      "Arviat",
      "Baker Lake",
      "Cambridge Bay",
    ],
  },
  {
    name: "Yukon",
    cities: [
      "Whitehorse",
      "Dawson City",
      "Watson Lake",
      "Haines Junction",
      "Faro",
    ],
  },
];
