import { Dropdown, Pagination, Table } from "flowbite-react";
import moment from "moment";
import { HiChatBubbleLeftRight } from "react-icons/hi2";
import { Filter } from "../../components/filters/filter-btn";
import { getRequestStateName, RequestState } from "../../types/request-status";
import { useState } from "react";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import apiCaller from "../../utils/api-caller";
import { Request } from "../../types/request";
import { useAuthStore } from "../../stores/auth-store";
import { DEFAULT_CACHE_CONFIG } from "../../constants/api";
import { getTotalPages } from "../../utils/pagination";
import { PAGINATION_SIZE } from "../../constants/pagination";
import { data, Link } from "react-router-dom";

export function Requests() {
  const [type, setType] = useState<string>("all");
  const [page, setPage] = useState<number>(1);

  const userId = useAuthStore((state) => state.user?._id);
  const requests = useQuery({
    queryKey: ["requests", type, page],
    queryFn: () =>
      apiCaller<{ length: number; requests: Request[] }>({
        method: "GET",
        url: `/owners/${userId}/requests`,
        params: {
          type,
          page,
          size: PAGINATION_SIZE,
        },
      }),
    placeholderData: keepPreviousData,
    ...DEFAULT_CACHE_CONFIG,
  });
  const queryClient = useQueryClient();
  const changeRequestState = useMutation({
    mutationFn: (data: { requestId: string; state: RequestState }) =>
      apiCaller<void>({
        method: "POST",
        url: `/owners/${userId}/requests/${data.requestId}/state`,
        body: JSON.stringify({ state: data.state }),
      }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["requests"] });
    },
  });

  return (
    <div className="w-full h-full flex flex-col gap-5 max-sm:p-2 sm:p-4">
      <Filter
        label="filter"
        onSelect={setType}
        items={[
          { name: "All", id: "all" },
          { name: "Tickets", id: "tickets" },
          { name: "New", id: RequestState.IDLE },
          { name: "Accept", id: RequestState.ACCEPT },
          { name: "In Progress", id: RequestState.IN_PROGRESS },
          { name: "Sent", id: RequestState.SENT },
          { name: "Reject", id: RequestState.REJECT },
        ]}
      />
      <div className="relative overflow-x-auto">
        <Table hoverable className="w-full">
          <Table.Head>
            <Table.HeadCell>service</Table.HeadCell>
            <Table.HeadCell>patient</Table.HeadCell>
            <Table.HeadCell>Date</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>
              <span className="sr-only">Edit</span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {requests.data?.requests.map((request, indx) => {
              return (
                <Table.Row
                  key={request._id}
                  className="bg-white border-x border-neutral-200 dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {request.service}
                  </Table.Cell>
                  <Table.Cell>{request.patient.userName}</Table.Cell>
                  <Table.Cell>
                    {moment(request.createdAt).format("DD MMM YYYY, hh:mm")}
                  </Table.Cell>
                  <Table.Cell className="hover:text-blue-700">
                    <Dropdown label={request.state} inline arrowIcon={false}>
                      {Object.values(RequestState).map((state) => (
                        <Dropdown.Item
                          key={state}
                          onClick={() => {
                            changeRequestState.mutate({
                              requestId: request._id,
                              state: state,
                            });
                          }}
                        >
                          {getRequestStateName(state)}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      to={`/requests/${request._id}/chat`}
                      className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                    >
                      <div className="w-fit relative">
                        <HiChatBubbleLeftRight size={20} />
                        {request.patientNewMessage ? (
                          <p className="absolute -top-2 -right-2 bg-neutral-700 text-white rounded-full flex items-center justify-center text-[10px] w-4 h-4">
                            {request.patientNewMessage}
                          </p>
                        ) : null}
                      </div>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Pagination
          currentPage={page}
          previousLabel="prev"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: ".5rem",
          }}
          totalPages={getTotalPages(PAGINATION_SIZE, data?.length || 0)}
          onPageChange={setPage}
        />
      </div>
    </div>
  );
}
