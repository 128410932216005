import React from "react";
import { EventAttachments } from "./components/event-attachment";
import { EventDateTime } from "./components/event-datettime";
import { EventReminder } from "./components/event-reminder";
import { EventRecurrency } from "./components/recurrency";
import { FolloupEventMetadata } from "../../types/followup";
import { FaUserAlt } from "react-icons/fa";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { useFollowupStore } from "../../stores/followup-store";

export default function Followup() {
  const followup = useFollowupStore((state) => state.followUp);
  if (!followup) {
    return null;
  }
  return (
    <div className="w-full mx-auto p-6 bg-white rounded-xl shadow-lg">
      <div className="space-y-6">
        {/* Title */}
        <h1 className="text-2xl font-bold text-gray-900">{followup.summary}</h1>

        {/* Patient Name */}
        <div className="flex items-center gap-2 text-gray-700">
          <FaUserAlt className="w-5 h-5" />
          <span className="font-medium">
            {(followup.extendedProperties?.shared as FolloupEventMetadata).name}
          </span>
        </div>

        {/* Date and Time */}
        <EventDateTime
          startDate={new Date(followup.start.dateTime as string)}
          endDate={new Date(followup.end.dateTime as string)}
        />

        {/* Recurrency */}
        <EventRecurrency recurrence={followup.recurrence} />

        {/* Reminder */}
        <EventReminder reminders={followup.reminders} />

        {/* Description */}
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <BsFillFileEarmarkTextFill className="w-5 h-5" />
            <h3 className="font-medium">Description</h3>
          </div>
          <p className="text-gray-700 whitespace-pre-wrap">
            {followup.description}
          </p>
        </div>

        {/* Attachments */}
        <EventAttachments
          attachment={
            (followup.extendedProperties?.shared as FolloupEventMetadata)
              ?.file_name
              ? {
                  name: (
                    followup.extendedProperties?.shared as FolloupEventMetadata
                  )?.file_name as string,
                  size: (
                    followup.extendedProperties?.shared as FolloupEventMetadata
                  )?.file_size as string,
                  url: (
                    followup.extendedProperties?.shared as FolloupEventMetadata
                  )?.file_url as string,
                }
              : undefined
          }
        />
      </div>
    </div>
  );
}
