
import { Button, Checkbox, getTheme, Label, TextInput } from "flowbite-react";
import logo from "../../assets/images/logo.png"
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { MouseEventHandler, useState } from "react";
import ClassNames from "classnames";

const passIcon = (visibility: boolean, callback: MouseEventHandler) => {
    const Icon = visibility ?  FaEye:FaEyeSlash;
    const className = 'text-congress-blue-900';
    return (
        <Icon
            className={ClassNames(getTheme().textInput.field.icon.svg, 'cursor-pointer', visibility && className)}
            onClick={callback}
        />
    );
}

export function ForgetPass() {
    const [show, setShow] = useState<boolean>(false)
    function togglePasswordVisibility() {
        setShow(!show);
    }

    return (
        <div className="w-screen h-screen flex items-center justify-center bg-neutral-50">
            <form className="flex max-w-md flex-col gap-4 shadow-md scale-in bg-white rounded-lg p-8 sm:min-w-[370px]">
                <div className="flex flex-col w-auto justify-center gap-2 items-center">
                    <img src={logo} alt="logo" className="" width={50} height={50} />
                    <p className="title capitalize text-neutral-700 text-2xl">admin panel</p>
                </div>

                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="password1" value="Your password" />
                    </div>
                    <TextInput
                        id="password1"
                        type={show ? "text" : "password"}
                        rightIcon={() => passIcon(show, togglePasswordVisibility)}
                        theme={{
                            field: {
                                icon: {
                                    base: getTheme().textInput.field.icon.base.replace('pointer-events-none', ' '),
                                }
                            }
                        }}
                        required />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="password1" value="Repeat Your password" />
                    </div>
                    <TextInput
                        id="password1"
                        type={show ? "text" : "password"}
                        rightIcon={() => passIcon(show, togglePasswordVisibility)}
                        theme={{
                            field: {
                                icon: {
                                    base: getTheme().textInput.field.icon.base.replace('pointer-events-none', ' '),
                                }
                            }
                        }}
                        required />
                </div>

                <Button type="submit">Submit</Button>
                <a href="/login" className="text-neutral-400 hover:text-blue-800 hover:underline w-fit">login</a>
            </form>
        </div>
    );
}
