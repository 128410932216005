import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { User } from "../types/user";

interface AuthStore {
  user: User | null;
  loading: boolean;
  logout: () => void;
  setUSer: (user: User) => void;
}

export const useAuthStore = create<AuthStore>(
  //@ts-ignore
  persist(
    (set) => ({
      user: null,
      loading: false,
      logout() {
        set({ user: null });
      },
      setUSer(user) {
        set({ user: user, loading: false });
      },
    }),
    {
      name: "auth-store", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
