import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, ButtonGroup, Modal, Table } from "flowbite-react";
import { useState } from "react";
import { PAGINATION_SIZE } from "../../../constants/pagination";
import { FolloupEventMetadata } from "../../../types/followup";
import { BsFillTrashFill } from "react-icons/bs";
import apiCaller from "../../../utils/api-caller";
import { useAuthStore } from "../../../stores/auth-store";
import moment from "moment";
import { setFollowup } from "../../../stores/followup-store";
import { useNavigate } from "react-router-dom";

export default function FollowupsTable() {
  const [openModal, setOpenModal] = useState<{ add: boolean; delete: boolean }>(
    { add: false, delete: false }
  );
  const [pageToken, setPageToken] = useState<string | undefined>(undefined);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>(
    undefined
  );
  const [selectedItem, setSelectedItem] = useState<{
    id: string;
    title: string;
    patient: string;
  }>();
  const calenderId = useAuthStore((state) => state.user?.calender?.id);
  const followups = useQuery({
    queryKey: ["followups", pageToken],
    queryFn: () =>
      apiCaller<gapi.client.calendar.Events>({
        method: "GET",
        url: "/followups",
        params: pageToken
          ? {
              calendarId: calenderId,
              timeMin: new Date().toISOString(),
              singleEvents: false,
              pageToken: pageToken,
              maxResults: PAGINATION_SIZE,
            }
          : {
              calendarId: calenderId,
              timeMin: new Date().toISOString(),
              singleEvents: false,
              maxResults: PAGINATION_SIZE,
            },
      }).then((result: gapi.client.calendar.Events) => {
        const nextPageToken = result.nextPageToken;
        setNextPageToken(nextPageToken);
        return result.items;
      }),
    enabled: !!calenderId,
  });
  const queryClient = useQueryClient();
  const deleteFollowup = useMutation({
    mutationFn: (id: string) =>
      apiCaller<void>({
        method: "DELETE",
        url: `/followups/${id}`,
      }),
    onSuccess() {
      const iframe = document.getElementById(
        "google-calender-iframe"
      ) as HTMLIFrameElement;
      if (iframe) {
        const src = iframe.src;
        iframe.src = "";
        iframe.src = src;
      }
      setOpenModal((prev) => ({ ...prev, delete: false }));
      setSelectedItem(undefined);
      queryClient.invalidateQueries({ queryKey: ["followups"] });
    },
  });
  const navigate = useNavigate();
  return (
    <div className="w-full h-full flex flex-col gap-5 px-4">
      <div className="relative overflow-x-auto">
        <Table hoverable className="w-full table-auto overflow-scroll ">
          <Table.Head>
            <Table.HeadCell>title</Table.HeadCell>
            <Table.HeadCell>patient</Table.HeadCell>
            <Table.HeadCell>date</Table.HeadCell>
            <Table.HeadCell>attachments</Table.HeadCell>
            <Table.HeadCell>description</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {followups.data?.map((followup) => {
              return (
                <Table.Row
                  key={followup.id}
                  className="bg-white border-x border-neutral-200 cursor-pointer "
                  onClick={() => {
                    setFollowup(followup);
                    navigate(`/followups/${followup.id}`);
                  }}
                >
                  <Table.Cell
                    colSpan={2}
                    className="whitespace-nowrap w-[150px] text-wrap pr-0 flex items-center gap-1 font-medium text-gray-900 "
                  >
                    {followup.summary}
                  </Table.Cell>
                  <Table.Cell className="text-nowrap">
                    {
                      (
                        followup.extendedProperties
                          ?.shared as FolloupEventMetadata
                      )?.name
                    }
                  </Table.Cell>
                  <Table.Cell className="text-nowrap">
                    {moment(followup.start.dateTime).format(
                      "DD MMM YYYY, hh:mm"
                    )}
                  </Table.Cell>
                  <Table.Cell className="text-nowrap">
                    {(
                      followup.extendedProperties
                        ?.shared as FolloupEventMetadata
                    )?.file_name
                      ? (
                          followup.extendedProperties
                            ?.shared as FolloupEventMetadata
                        ).file_name
                      : "_"}
                  </Table.Cell>
                  <Table.Cell>{followup.description}</Table.Cell>
                  <Table.Cell
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal((prevState) => ({
                        ...prevState,
                        delete: true,
                      }));
                      setSelectedItem({
                        id: followup.id,
                        title: followup.summary,
                        patient: (
                          followup.extendedProperties
                            ?.shared as FolloupEventMetadata
                        )?.name as string,
                      });
                    }}
                  >
                    <button className="rounded-full flex items-center justify-center w-8 h-8 hover:bg-primary-400">
                      <BsFillTrashFill />
                    </button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <ButtonGroup className="mt-4 w-full items-center justify-center">
          <Button
            onClick={() => {
              setPageToken(undefined);
              setNextPageToken(undefined);
            }}
            disabled={!pageToken}
          >
            Reset
          </Button>
          <Button
            disabled={!nextPageToken}
            onClick={() => {
              setPageToken(nextPageToken);
              setNextPageToken(undefined);
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>
      <Modal
        show={openModal.delete}
        onClose={() =>
          setOpenModal((prevState) => ({ ...prevState, delete: false }))
        }
      >
        <Modal.Header className="uppercase">delete followup</Modal.Header>
        <Modal.Body className="">
          <p className="text-base w-full text-center leading-relaxed text-gray-500">
            Are you sure you want to delete this followup ({selectedItem?.title}
            )?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-center gap-4">
          <Button
            className="min-w-40"
            onClick={() => {
              deleteFollowup.mutate(selectedItem?.id as string);
            }}
          >
            delete
          </Button>
          <Button
            className="min-w-40"
            color="gray"
            onClick={() =>
              setOpenModal((prevState) => ({ ...prevState, delete: false }))
            }
          >
            cancle
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
