import { Avatar } from "flowbite-react";
import { Message, MessageState } from "../../types/message";
import State from "./messageState";
import moment from "moment";

export default function TextMessage(props: Message & { userId: string }) {
  const initials = props.sender.userName
    .split(" ")
    .map((item) => item[0])
    .join("");

  const fromUser = props.sender._id !== props.userId;
  return (
    <div
      className={`${
        !fromUser && "self-end flex-row-reverse"
      } flex items-start gap-2.5`}
    >
      {fromUser && <Avatar placeholderInitials={initials} rounded />}
      <div
        className={`flex flex-col w-full max-w-[320px] leading-1.5 max-sm:p-2 sm:p-4 border-gray-200 ${
          fromUser
            ? " rounded-e-xl rounded-es-xl bg-gray-100 "
            : "rounded-xl rounded-tr-none bg-blue-200"
        } `}
      >
        <div
          className={`flex items-center justify-between w-full space-x-2 rtl:space-x-reverse ${
            !fromUser && "self-end flex-row-reverse gap-2"
          }`}
        >
          <span className="text-sm max-sm:text-xs font-semibold text-gray-900 dark:text-white">
            {props.sender.userName}
          </span>
          <span className="text-sm  max-sm:text-xs font-normal text-gray-500 dark:text-gray-400">
            {moment(props.createdAt).format("hh:mm")}
          </span>
        </div>
        <p className="text-sm max-sm:text-xs font-normal py-2.5 text-gray-900 dark:text-white">
          {props.message}
        </p>
        {!fromUser && (
          <span
            className={`text-sm max-sm:text-xs font-normal text-gray-500 dark:text-gray-400 `}
          >
            <State
              state={
                props.state === undefined ? MessageState.SENT : props.state
              }
            />
          </span>
        )}
      </div>
    </div>
  );
}
