export function getFileSize(size: number, decimalPoint?: number) {
  if (size === 0) return "0 Bytes";

  const k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB"];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getFileType(name: string) {
  const ext = (name || "").split(".").pop() || "unknown";
  return ext.toUpperCase();
}

export function getFileLink(url: string): string {
  const base =
    process.env.NODE_ENV === "development"
      ? "https://owner.pharmacanada.ca/statics"
      : "/statics";
  return `${base}/${url}`;
}
