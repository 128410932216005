import { useState, useRef, useEffect } from "react";
import { provinces } from "../../constants/canadaProvince";

interface Province {
  name: string;
  cities: string[];
}

export default function LocationInput({
  defaultValue,
  onChange,
}: {
  defaultValue?: {
    province: string;
    city: string;
  };
  onChange?: (province: string, city: string) => void;
}) {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(
    defaultValue?.province || ""
  );
  const [selectedProvince, setSelectedProvince] = useState<Province | null>(
    null
  );
  const [selectedCity, setSelectedCity] = useState<string>(
    defaultValue?.city || ""
  );
  const ref = useRef<HTMLDivElement>(null);

  const filteredProvinces = provinces.filter((province) =>
    province.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleProvinceSelect = (province: Province) => {
    setSelectedProvince(province);
    setSearchTerm(province.name);
    setOpen(false); // Close the dropdown after selecting a province
  };

  const handleCitySelect = (city: string) => {
    setSelectedCity(city);
    if (onChange) {
      onChange(selectedProvince?.name as string, city);
    }
    setSearchTerm(selectedProvince?.name as string);
    setShow(false); // Close the dropdown after selecting a city
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative text-black mb-2 w-full" ref={ref}>
      <div className="flex">
        <input
          type="text"
          name="province"
          className="border border-neutral-300 rounded-l-lg text-sm p-3 pl-7 w-full"
          placeholder="province"
          value={searchTerm}
          onClick={() => setOpen(!open)}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <input
          type="text"
          name="city"
          className="border border-neutral-300 rounded-r-lg text-sm p-3 w-full"
          placeholder="city"
          value={selectedCity}
          onClick={() => setShow(!open)}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="currentColor"
        className="bi bi-geo-alt-fill text-blue-800 absolute top-[15px] left-2"
        viewBox="0 0 16 16"
      >
        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
      </svg>
      {open && (
        <div className=" absolute left-0 z-[100] max-h-[20vh] overflow-auto bg-[#fafafa] border rounded shadow-md mt-1 w-[300px]">
          {filteredProvinces.map((province) => (
            <div
              key={province.name}
              className="p-2 max-sm:text-xs sm:text-sm hover:bg-primary-50 rounded-md cursor-pointer"
              onClick={() => handleProvinceSelect(province)}
            >
              {province.name}
            </div>
          ))}
        </div>
      )}
      {show && selectedProvince && (
        <div className=" absolute right-0 z-[100] max-h-[20vh] overflow-auto bg-[#fafafa] border rounded shadow-md mt-1 w-[300px]">
          {selectedProvince.cities.map((city) => (
            <div
              key={city}
              className="p-2 text-black max-sm:text-xs sm:text-sm hover:bg-primary-50 rounded-md cursor-pointer"
              onClick={() => handleCitySelect(city)}
            >
              {city}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
